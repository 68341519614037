import React, { Component } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

export default class episodes extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Sponsors" />
      </Layout>
    )
  }
}
